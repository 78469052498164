import React from "react";
import { Menu } from "antd";
import { navigate } from "gatsby";
import {
  TranslationOutlined,
  QrcodeOutlined,
  FormOutlined,
  FileUnknownOutlined,
  FileTextOutlined,
  FileDoneOutlined,
  UserOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { useLocation } from "@reach/router";
import { ACTION, SECTION, SectionAuth } from "@services/auth";
export const menuLinks = [
  {
    id: "StaticQuestionTitle",
    page: "Questions",
    path: "/admin/question/choicelist",
    icon: <FileUnknownOutlined />,
  },
  {
    id: "StaticFormTitle",
    page: "Forms",
    path: "/admin/forms",
    icon: <FormOutlined />,
  },
  {
    id: "StaticTemplateTitle",
    page: "Templates",
    path: "/admin/template",
    icon: <FileTextOutlined />,
  },
  {
    id: "StaticAssessmentTitle",
    page: "Assessments",
    path: "/admin/assessments",
    icon: <FileDoneOutlined />,
  },
  {
    id: "StaticReportTitle",
    page: "Reports",
    path: "/admin/report/scoringkeyset",
    icon: <FileSearchOutlined />,
  },
  {
    id: "StaticClientTitle",
    page: "Clients",
    path: "/admin/clients",
    icon: <UserOutlined />,
  },
  {
    id: "StaticClientGroupTitle",
    page: "ClientGroups",
    path: "/admin/clientgroup",
    icon: <UserOutlined />,
  },
  {
    id: "StaticRegistrationPeriodTitle",
    page: "RegistrationPeriod",
    path: "/admin/registrationperiod",
    icon: <QrcodeOutlined />,
  },
  {
    id: "StaticClientUserTitle",
    page: "ClientUsers",
    path: "/admin/clientusers",
    icon: <UserOutlined />,
  },
  {
    id: "StaticClientAdminTitle",
    page: "ClientAdmins",
    path: "/admin/clientadmins",
    icon: <UserOutlined />,
  },
  {
    id: "StaticAdministratorTitle",
    page: "Administrator",
    path: "/admin/administrator",
    icon: <UserOutlined />,
  },
  {
    id: "StaticLanguageTitle",
    page: "Language",
    path: "/admin/language",
    icon: <TranslationOutlined />,
  },
  {
    id: "StaticSurveyTitle",
    page: "Survey",
    path: "/admin/survey",
    icon: <FileUnknownOutlined />,
  },
  {
    id: "StaticBillingInformationTitle",
    page: "Billing",
    path: "/admin/billing/assessment/summary",
    icon: <FileSearchOutlined />,
  },
];

const LinkMenu = () => {
  const location = useLocation();
  const handleClick = ({ key }: any) => {
    switch (key) {
      case "myTask":
        navigate("/");
        break;

      default:
        navigate(key);
        break;
    }
  };

  const auth = new SectionAuth(SECTION.Admin);

  const menuItems = [];
  for (const menuLink of menuLinks) {
    if (auth.can(ACTION.Access, menuLink.page)) {
      menuItems.push(
        <Menu.Item key={menuLink.path} icon={menuLink.icon}>
          <FormattedMessage id={menuLink.id} />
        </Menu.Item>
      );
    }
  }
  return (
    <Menu
      mode="vertical"
      selectedKeys={[location.pathname]}
      onClick={handleClick}
    >
      {menuItems}
    </Menu>
  );
};

export default LinkMenu;
