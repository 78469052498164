import { ACTION, SECTION, SectionAuth } from "@services/auth";
import { navigate } from "gatsby";
import React, { useEffect } from "react";

import { menuLinks } from "../../layout/LinkMenu";

const IndexPage = () => {
  useEffect(() => {
    const auth = new SectionAuth(SECTION.Admin);
    let path = "/403";
    for (const adminPage of menuLinks) {
      if (auth.can(ACTION.Access, adminPage.page)) {
        path = adminPage.path;
        break;
      }
    }
    navigate(path);
  });
  return <></>;
};

export default IndexPage;
